// extracted by mini-css-extract-plugin
export var activeDot = "product-page-module--activeDot--a12dc";
export var addToCartStyle = "product-page-module--addToCartStyle--af90b";
export var breadcrumb = "product-page-module--breadcrumb--4d4af";
export var container = "product-page-module--container--11112";
export var dot = "product-page-module--dot--d51ec";
export var dots = "product-page-module--dots--e4e75";
export var header = "product-page-module--header--6409e";
export var imageControl = "product-page-module--imageControl--6348b";
export var imageControls = "product-page-module--imageControls--42c8e";
export var imageWrapperInner = "product-page-module--imageWrapperInner--57cd2";
export var labelFont = "product-page-module--labelFont--9cc3e";
export var metaSection = "product-page-module--metaSection--95208";
export var noImagePreview = "product-page-module--noImagePreview--bb35b";
export var optionsWrapper = "product-page-module--optionsWrapper--8c7f7";
export var priceValue = "product-page-module--priceValue--824d0";
export var productBox = "product-page-module--productBox--7eeb6";
export var productDescription = "product-page-module--productDescription--18abb";
export var productImageList = "product-page-module--productImageList--e9c44";
export var productImageListItem = "product-page-module--productImageListItem--3f7f9";
export var productImageWrapper = "product-page-module--productImageWrapper--fbe22";
export var productInfo = "product-page-module--productInfo--2c61c";
export var scrollForMore = "product-page-module--scrollForMore--59c73";
export var selectVariant = "product-page-module--selectVariant--9bf80";
export var tagList = "product-page-module--tagList--239b0";
export var visuallyHidden = "product-page-module--visuallyHidden--3f1f3";